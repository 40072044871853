<template>
    <div class="container">
        <div class="lef">
            <div class="item bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="title">设备统计</div>
                <div class="content">
                    <total :syncId="syncId"></total>
                </div>
            </div>
            <div class="item bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="wrapTit">
                    <p class="title">
                        商户情况
                    </p>
                    <div class="types">
                        <div @click="switchType(0)" :class="activeType == 0 ? 'active' : ''" class="sin">今日</div>
                        <div @click="switchType(1)" :class="activeType == 1 ? 'active' : ''" class="sin">本周</div>
                        <div @click="switchType(2)" :class="activeType == 2 ? 'active' : ''" class="sin">本月</div>
                    </div>
                </div>
                <div style="overflow: hidden;padding: 0.8em 0;" class="content">
                    <realTable style="height:100%;" :type="activeType" :syncId="syncId"></realTable>
                    <!-- <topReader/> -->
                </div>
            </div>
            <div class="item bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="title">近一周刷卡情况</div>
                <div class="content">
                    <lineChart :syncId="syncId"></lineChart>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="col-66 align">
                <div class="col-53 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">就餐人数/人次</div>
                    <div class="">
                        <eat :syncId = "syncId"></eat>
                        <!-- <div class="picCon">
                            <img class="bgPic" src="../assets/images/bg_chart@2x.png" alt="">
                        </div> -->
                        <!-- <pie ref="chart3" id="pie_1" :data="pieData1"></pie> -->
                    </div>
                    <div style="margin-top: 0.5em;" class="title">就餐情况(就餐人数/应就餐人数)</div>
                    <div class="content">
                        <range :syncId="syncId"></range>
                        <!-- <total/> -->
                    </div>
                </div>
                <div class="col-47 lef-1 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">消费情况</div>
                    <div style="padding: 0.8em 0;" class="content">
                        <consume :syncId="syncId"></consume>
                        <!-- <total/> -->
                    </div>
                </div>
            </div>
            <div class="col col32 bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="wrapTit">
                    <div class="title">班级消费情况(人数)</div>
                    <DatePicker @on-change="handleChange" type="daterange" placeholder="请选择时间" style="width: 18em" />
                </div>
                <div class="content">
                    <bar :time="time" :syncId="syncId"></bar>
                    <!-- <realTable/> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const realTable = ()=> import('./components/dinnerCharts/table');
const total = ()=> import('./components/dinnerCharts/total');
const consume = ()=> import('./components/dinnerCharts/consume');
const range = ()=> import('./components/dinnerCharts/range');
const eat = ()=> import('./components/dinnerCharts/eat');
const lineChart = ()=> import('./components/dinnerCharts/line');
const bar = ()=> import('./components/dinnerCharts/bar');
import moment from "moment"

export default {
    components: {
        realTable,
        total,
        // top,
        // topReader,
        lineChart,
        bar,
        consume,
        eat,
        range
    },
    name: '',
    data() {
        return {
            pieData1: {// 饼图数据1
                title: "",
                color: '#2C7BFE',
                data: [
                    {
                        value: 60,
                        name: '体育',
                        itemStyle: {
                            color: '#1456FE'
                        }

                    },
                    {
                        value: 20,
                        name: '文化',
                        itemStyle: {
                            color: '#FF4A22'
                        }
                    },
                    {
                        value: 20,
                        name: '教育',
                        itemStyle: {
                            color: '#FFCD3E'
                        }
                    },
                    {
                        value: 20,
                        name: '科学',
                        itemStyle: {
                            color: '#3EF3FF'
                        }
                    },
                ],
            },
            syncId: "",
            activeType: 0,
            time: [moment().subtract(1,"week").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")]
        }
    },
    mounted() {
        // this.initData()
    },
    created() {
        if (this.$route.query) {
            this.syncId = this.$route.query.syncId
        }
    },
    methods: {
        switchType(type) {
            this.activeType = type
        },
        handleChange(e) {
            this.time = e            
        }
    },
}
</script>

<style scoped lang="less">
    .container{
        height: 100%;
        padding: 20px 60px;
        display: flex;
        // background: url(../assets/images/Group\ 1329@2x.png) no-repeat center;
        background-size: 100% 100%;
        // box-sizing: border-box;
        overflow: hidden;
        background: rgba(255,255,255,0);

        .align{
            display: flex;
            align-items: center;
        }
        .bor{
            border: 1px solid #4067DE;
            position: relative;
            border-radius: 11px 11px 11px 11px;
            padding: 1em 1.3em;
            display: flex;
            flex-direction: column;
            // border-image: url('../assets/images/frame_lt@2x.png') 30 30 round;
            .content{
                flex: 1;
                position: relative;
            }
            .bg{
                .picCon{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                img{
                    width: 45%;
                    height: auto;
                }
            }   
            img{
                width: 1.7em;
                height: auto;
            }
            .radius {
                width: 1.7em;
                height: 1.7em;
            }
            .lefTop{
                position: absolute;
                left: -1px;
                top: -1px;
                background-color: rgba(255,255,255,0);
                z-index: 111;
            }
            .rigTop{
                position: absolute;
                right: -1px;
                top: -1px;
                background-color: rgba(255,255,255,0);
                z-index: 111;
            }
            .lefBot{
                position: absolute;
                left: -1px;
                bottom: -1px;
                z-index: 111;
                background-color: rgba(255,255,255,0);
            }
            .rigBot{
                position: absolute;
                right: -1px;
                bottom: -1px;
                z-index: 111;
                background-color: rgba(255,255,255,0);
            }
            .end{
                display: flex;
                align-items: flex-end;
            }
        }
        .lef{
            width: 32%;
            display: flex;
            flex-direction: column;
            .item{
                &:first-child {
                    margin-top: 0;
                }
                margin-top: 2%;
                flex: 1;
            }

        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0% 1%;
            .col{
                flex: 1;
                &:nth-child(2) {
                    margin-top: 1%;
                }                
            }
            .col-66{
                height: 66.3%;
            }
            .col32{
                height: 32%;
            }
            .col-53{
                width: 53%;
                height: 100%;
            }
            .col-47{
                width: 47%;
                height: 100%;
            }
            .lef-1{
                margin-left: 1%;
            }
        }
        .title{
            width: 100%;
            height: 2em;
            display: flex;
            align-items: center;
            background: url(../assets/images/bg_title_s@2x.png) no-repeat left center;
            background-size: contain;
            color: #fff;
            font-size: 1.1em;
            position: relative;
            z-index: 1;
            padding-left: 1.8em;
            background-color: rgba(27, 126, 242, 0.3);
        }
        .wrapTit{
            display: flex;
            justify-content: space-between;
            .title{
                width: 40%;
                height: 2em;
                // height: 100%;
                background: url(../assets/images/bg_title_short@2x.png) no-repeat left center;
                background-size: contain;
            }
            .types {
                display: flex;
                align-items: center;
                .sin{
                    height: 2.3em;
                    width: 4.2em;
                    text-align: center;
                    line-height: 2.3em;
                    border: 1px solid #4F80FF;
                    border-radius: 4px;
                    margin-left: 1px;
                    color: #AACCFF;
                    font-size: 0.8em;
                    cursor: pointer;
                }
                .active{
                    box-shadow: inset 0px 0px 40px 1px #4F80FF;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #4F80FF;
                    color: #fff;
                }
 
            }
        }
        /deep/
        .ivu-input-with-suffix{
            // box-shadow: inset 0px 0px 40px 1px #4F80FF!important;
            border-radius: 4px 4px 4px 4px!important;
            border: 1px solid #4F80FF;
            color: #AACCFF ;
            background: rgba(64, 103, 222, 0.5);
        }
    } 
</style>
