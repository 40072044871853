<template>
    <div class="container">
        <div class="lef">
            <div class="col-36 col align">
                <div class="col-46 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">仓管图书类别统计</div>
                    <div class="content bg">
                        <div class="picCon">
                            <img class="bgPic" src="../assets/images/bg_chart@2x.png" alt="">
                        </div>
                        <pie ref="chart3" id="pie_1" :syncId="syncId"></pie>
                    </div>
                </div>
                <div class="col-54 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">数据概览</div>
                    <div class="content">
                        <total :syncId="syncId"/>
                    </div>
                </div>
            </div>
            <div class="col-34 col align">
                <div class="col-46 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">实时借阅情况</div>
                    <div style="overflow: hidden;" class="content">
                        <realTable :syncId="syncId"/>
                    </div>
                </div>
                <div class="col-54 bor">
                    <div class="lefTop radius">
                        <img src="../assets/images/frame_lt@2x.png" alt="">
                    </div>
                    <div class="rigTop radius">
                        <img src="../assets/images/frame_rb@2x.png" alt="">
                    </div>
                    <div class="lefBot end radius">
                        <img src="../assets/images/frame_lb@2x.png" alt="">
                    </div>
                    <div class="rigBot end radius">
                        <img src="../assets/images/frame_rt@2x.png" alt="">
                    </div>
                    <div class="title">近一周借阅情况</div>
                    <div class="content">
                        <lineChart :syncId="syncId"></lineChart>
                    </div>
                </div>
            </div>
            <div class="col-30 col bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <!-- <div style="padding-left: 40px;" class="title">班级借阅情况</div> -->
                <div class="wrapTit">
                    <div style="" class="title">班级借阅情况</div>
                    <DatePicker @on-change="handleChange" type="daterange" placeholder="请选择时间" style="width: 18em" />
                </div>
                <div class="content">
                    <bar :time="time" :syncId="syncId"></bar>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="item bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="title">图书热度排行</div>
                <div class="content">
                    <top :syncId="syncId"></top>
                </div>
            </div>
            <div class="item bor">
                <div class="lefTop radius">
                    <img src="../assets/images/frame_lt@2x.png" alt="">
                </div>
                <div class="rigTop radius">
                    <img src="../assets/images/frame_rb@2x.png" alt="">
                </div>
                <div class="lefBot end radius">
                    <img src="../assets/images/frame_lb@2x.png" alt="">
                </div>
                <div class="rigBot end radius">
                    <img src="../assets/images/frame_rt@2x.png" alt="">
                </div>
                <div class="title">读者阅读排行</div>
                <div class="content">
                    <topReader :syncId = "syncId"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const pie = ()=> import('./components/charts/pie');
const realTable = ()=> import('./components/charts/table');
const total = ()=> import('./components/charts/total');
const top = ()=> import('./components/charts/top');
const topReader = ()=> import('./components/charts/topReader');
const lineChart = ()=> import('./components/charts/line');
const bar = ()=> import('./components/charts/bar');
import moment from "moment"
export default {
    components: {
        pie,
        realTable,
        total,
        top,
        topReader,
        lineChart,
        bar
    },
    name: '',
    data() {
        return {
            syncId: "",
            time: [moment().subtract(1,"week").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")]
        }
    },
    created() {
        if (this.$route.query) {
            this.syncId = this.$route.query.syncId
        } 
    },
    methods: {
        handleChange(e) {
            this.time = e            
        }
    },
}
</script>

<style scoped lang="less">
    .container{
        height: 100%;
        padding: 20px 60px;
        display: flex;
        // background: url(../assets/images/Group\ 1329@2x.png) no-repeat center;
        background-size: 100% 100%;
        // box-sizing: border-box;
        overflow: hidden;
        background: rgba(255,255,255,0);
        .align{
            display: flex;
            align-items: center;
        }
        .bor{
            border: 1px solid #4067DE;
            position: relative;
            border-radius: 13px 13px 13px 13px;
            padding: 1em 1.3em;
            display: flex;
            flex-direction: column;
            // border-image: url('../assets/images/frame_lt@2x.png') 30 30 round;
            .content{
                flex: 1;
                position: relative;
                overflow:auto
            }
            .bg{
                .picCon{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                img{
                    width: 45%;
                    height: auto;
                }
            }   
            img{
                width: 1.7em;
                height: auto;
            }
            .radius {
                width: 1.7em;
                height: 1.7em;
            }
            .lefTop{
                position: absolute;
                left: -1px;
                top: -1px;
                background-color: rgba(255,255,255,0);
                z-index: 111;
            }
            .rigTop{
                position: absolute;
                right: -1px;
                top: -1px;
                background-color: rgba(255,255,255,0);
                z-index: 111;
            }
            .lefBot{
                position: absolute;
                left: -1px;
                bottom: -1px;
                z-index: 111;
                background-color: rgba(255,255,255,0);
            }
            .rigBot{
                position: absolute;
                right: -1px;
                bottom: -1px;
                z-index: 111;
                background-color: rgba(255,255,255,0);
            }
            .end{
                display: flex;
                align-items: flex-end;
            }
        }
        .lef{
            width: 68%;
            display: flex;
            flex-direction: column;
            height: 100%;
            .col{
                &:first-child {
                    margin-top: 0;
                }
                margin-top: 1%;
            }
            .col-36{
                height: 36%;
            }
            .col-34{
                height: 34%;
            }
            .col-30{
                width: 100%;
                height: 30%;
            }
            .col-46{
                width: 46%;
                height: 100%;
            }
            .col-54{
                width: 54%;
                height: 100%;
                margin-left: 1%;
            }
        }
        .right{
            width: 32%;
            // flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0% 1%;
            height: 100%;
            .item{
                flex: 1;
                // height: 50%;
                &:nth-child(2) {
                    margin-top: 2%;
                }
            }
        }
        .title{
            width: 100%;
            height: 2em;
            display: flex;
            align-items: center;
            background: url(../assets/images/bg_title_s@2x.png) no-repeat left center;
            background-size: contain;
            color: #fff;
            font-size: 1.1em;
            position: relative;
            z-index: 1;
            padding-left: 20px;
            background-color: rgba(27, 126, 242, 0.3);
        }
        .wrapTit{
            display: flex;
            justify-content: space-between;
            .title{
                width: 40%;
                height: 2em;
                // height: 100%;
                background: url(../assets/images/bg_title_s@2x.png) no-repeat left center;
                background-size: contain;
                background-color: rgba(27, 126, 242, 0.3);
            }
            .types {
                display: flex;
                align-items: center;
                .sin{
                    height: 2.3em;
                    width: 4.2em;
                    text-align: center;
                    line-height: 2.3em;
                    border: 1px solid #4F80FF;
                    border-radius: 4px;
                    margin-left: 1px;
                    color: #AACCFF;
                    font-size: 0.8em;
                    cursor: pointer;
                }
                .active{
                    box-shadow: inset 0px 0px 40px 1px #4F80FF;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    border: 1px solid #4F80FF;
                    color: #fff;
                }
 
            }
        }
        ::-webkit-scrollbar {
            width: 3px;
            // display: none;
            /* background-color:none !important; */  //去掉滚动条
            background-color:rgba(24,95,198,0) !important;    //配置滚动条颜色,颜色可以自己定义
        }
        ::-webkit-scrollbar-thumb {
            background-color:rgba(24,95,198,0.8) !important;    //配置滚动条颜色,颜色可以自己定义
            border-radius: 10px;
            height: 20px;
            width: 8px;
        }
        /deep/
        .ivu-input-with-suffix{
            // box-shadow: inset 0px 0px 40px 1px #4F80FF!important;
            border-radius: 4px 4px 4px 4px!important;
            border: 1px solid #4F80FF;
            color: #AACCFF ;
            background: rgba(64, 103, 222, 0.5);
        }
    } 
</style>
