import Vue from 'vue'
import Router from 'vue-router'
import library from '@/views/library';
import dining from '@/views/dining';

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/library'
        },
        {
            path: '/library',
            name: 'library',
            component: library,
        },
        {
            path: '/dining',
            name: 'dining',
            component: dining,
        }
    ]
})
export default router
